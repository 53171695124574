<template>
  <section class="agent-buttons">
    <img src="../assets/images/logo.svg" alt="wagerInternational">
    <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agent classic</a>
    <a class="primary-button primary-button__new" :href="agentNew" target="_blank">agent new</a>
  </section>
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const agentClassic = "https://agents.playsports527.com/";
      const agentNew = "https://adm.playsports527.com/home/landing";
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
